import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { graphql, withPrefix } from "gatsby"
import Image from "../components/Image"
import HubspotSignupForm from "../components/Hubspot"
import Layout from "../components/Layout"

import "../css/blog.scss"

function Section1({ title, richTitle }) {
  return richTitle ? (
    <h1
      className="h1 text---white"
      itemProp="title"
      dangerouslySetInnerHTML={{ __html: richTitle.html }}
    />
  ) : (
    <h1 className="h1 text---white" itemProp="title">
      {title}
    </h1>
  )
}

function Section2({ title, byline, bylinePhoto, headerImage, socialImage, date, body, tags }) {
  const tagsDefense = tags && tags.includes('defense');

  return (
    <article className="section">
      <header className="xl---padding-12---1-col md---block byline">
        <p
          className="author"
          itemScope
          itemProp="author"
          itemType="https://schema.org/Person"
        >
          <Image className="photo" itemProp="image" imageData={bylinePhoto} />
          <span itemProp="name">{byline}</span>
        </p>
        <p className="date" itemProp="pubdate">
          {date}
        </p>
      </header>
      {headerImage ? (
        <Image className="header-image" imageData={headerImage} />
      ) : (
        <></>
      )}
      <div
        className="xl---padding-12---1-col spacing---lg md---block article-body"
        dangerouslySetInnerHTML={{ __html: body }}
      />
      <a
        id="floating-return-to-top"
        href="javascript:window.scroll({ top: 0, left: 0, behavior: 'smooth' })"
      >
        🔝
      </a>
      <div id="floating-signup">
        <HubspotSignupForm />
      </div>
      <footer>
        {tagsDefense ? (
          <div className="defense-footer">
            <p>At <strong>Thesis Defense</strong>, we pride ourselves on our expertise. Our team of security auditors have many years of experience carrying out audits on decentralized systems across a number of technologies including <strong>smart contracts, wallets, bridges, consensus mechanisms,</strong> and <strong>cryptographic protocols</strong> and within a variety of ecosystems including <strong>Bitcoin, Ethereum, Polygon, Cosmos, Avalanche, Zcash, Stacks, Mina</strong> and more.</p>
            <p>To learn more about our services and get a free quote, <a href="https://calendly.com/d/2zm-t3y-xjq/thesis-defense?month=2024-02" target="_blank">schedule a call</a> or email us @ <a href="mailto:defense@thesis.co">defense@thesis.co</a>. For more information about Thesis Defense, visit our <a href="https://thesis.co/defense" target="_blank">website</a> and our <a href="https://medium.com/thesis-defense" target="_blank">blog.</a></p>
          </div>
        ) : (
          <HubspotSignupForm />
        )}
      </footer>
    </article>
  )
}

function absolutizeUrl(url: string) {
  const prefixed = withPrefix(url)
  if (prefixed === url) {
    return `https://thesis.co${prefixed}`
  }

  return `https://preview.thesis.co${prefixed}`
}

function BlogPost({ data }) {
  const { markdownRemark: post } = data
  const {
    frontmatter: { title, byline, headerImage, socialImage },
    fields: { slug },
  } = post

  const pageClass = slug.replace(/^\/?(.*?)\/?$/, "$1").replace(/\//g, "--")
  const defaultImage = "share_preview.png"
  const ogImage = absolutizeUrl(
    `/images/${
      (socialImage ?? headerImage)?.image?.relativePath
      ?? defaultImage
    }`
  )

  return (
    <Layout className={`blog-page ${pageClass}`} hideEmbellishment>
      <Helmet>
        <title>{title}</title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:title" content={title} />
        <meta property="twitter:title" content={title} />
        <meta property="og:url" content={withPrefix(`${slug}`)} />
        <meta property="og:description" content={byline} />
        <meta property="og:image" content={ogImage} />
        <meta property="twitter:image" content={ogImage} />
      </Helmet>
      <Section1 {...post.frontmatter} />
      <Section2 body={post.html} {...post.frontmatter} />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        richTitle {
          html
        }
        date(formatString: "MMMM DD YYYY")
        byline
        bylinePhoto {
          image {
            relativePath
          }
          alt
        }
        headerImage {
          image {
            relativePath
          }
          alt
        }
        socialImage {
          image {
            relativePath
          }
          alt
        }
        tags
      }
    }
  }
`
