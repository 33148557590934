import React, { useState } from "react"
import axios from "axios"

export default function HubspotSignupForm() {
  const [email, setEmail] = useState<string>("")
  const [message, setMessage] = useState<string>("")

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const portalId = "46383448" 
    const formId = "5b3559bf-18ed-4bd2-9ace-4a5879c8d5d1"

    const data = {
      fields: [
        {
          name: "email",
          value: email,
        },
      ],
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
    }

    try {
      const response = await axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )

      if (response.status === 200) {
        setMessage("Thanks for subscribing!")
      } else {
        setMessage("Oops, it didn't work!")
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 409) {
          setMessage("Looks like you're already subscribed!")
        } else {
          setMessage("Oops, it didn't work!")
        }
      } else {
        setMessage("An unexpected error occurred.")
      }
    }
  }

  return (
    <div>
      <h3>Sign up for the Thesis* newsletter</h3>
      <p>
        You&apos;ll receive blog posts about company building, crypto, and more
        in your inbox, about twice per month.
      </p>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">
          Enter your email:
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ padding: "1em" }}
          />
        </label>
        <button
          type="submit"
          style={{ background: "black", color: "white", padding: "1em" }}
        >
          Subscribe
        </button>
        {message && <p style={{ padding: "1em 0" }}>*{message}</p>}
      </form>
    </div>
  )
}
